<template>
  <div class="owner-list">
    <div class="owner-list__title-wrapper">
      <TitleBase>{{ titleStandOwner }}</TitleBase>
    </div>
    <TableList
      :isReady="isPageReady"
      :headers="headersOwner"
      :items="currentOwners"
    >
      <template v-slot:item.owner="{ item }">
        <tr>
          <td>
            <span @click="removeOwner(item)">
              <Round :type="2">
                <v-icon dark>mdi-delete</v-icon>
              </Round>
            </span>
          </td>
        </tr>
      </template>
    </TableList>
    <div class="owner-list__title-wrapper">
      <TitleBase>{{ titlePlatformUser }}</TitleBase>
    </div>
    <TableList
      :isReady="isPageReady"
      :headers="headers"
      :items="model"
      :footer-props="footerProps"
      :ssp="true"
      :ssp-length="sspLength"
      :current-filters.sync="currentFilters"
      @update:ssp="updateSsp"
      ref="usersTable"
    >
      <template v-slot:item.owner="{ item }">
        <tr>
          <td>
            <span @click="addOwner(item)">
              <Round :type="2">
                <v-icon dark>mdi-pencil</v-icon>
              </Round>
            </span>
          </td>
        </tr>
      </template>
    </TableList>
  </div>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";
import Round from "@/components/dom/Round";
import TableList from "@/components/table/TableList";
import TitleBase from "@/components/dom/TitleBase";

@Component({
  components: {
    Round,
    TableList,
    TitleBase,
  },
  inject: {
    groupService: "groupService",
    toastService: "toastService",
    userService: "userService",
    standService: "standService",
  },
})
export default class OwnerList extends Vue {
  isPageReady = false;
  model = [];
  groups = [];
  currentOwners = [];
  ownerGroup = [];
  sspLength = 0;
  footerProps = {
    itemsPerPageOptions: [5, 10, 15, 20],
  };
  currentFilters = null;

  get translations() {
    return this.$store.state.translationsStore.currentLang;
  }

  get titleStandOwner() {
    return this.translations.labels.title_stand_owner;
  }

  get titlePlatformUser() {
    return this.translations.labels.title_plat_user;
  }

  get headers() {
    return [
      {
        text: this.translations.labels.user_table_email,
        value: "email",
      },
      {
        text: this.translations.labels.user_table_lastName,
        value: "lastName",
      },
      {
        text: this.translations.labels.user_table_firstName,
        value: "firstName",
      },
      {
        text: this.translations.labels.user_table_username,
        value: "username",
      },
      {
        text: "Owner",
        value: "owner",
        type: "button",
        icon: "mdi-pencil",
        buttonType: 2,
      },
    ];
  }

  get headersOwner() {
    return [
      {
        text: this.translations.labels.user_table_email,
        value: "email",
      },
      {
        text: "Owner",
        value: "owner",
        type: "button",
        icon: "mdi-delete",
        buttonType: 2,
      },
    ];
  }

  async removeOwner(owner) {
    let proceed = confirm(this.translations.success.remove_owner);
    const standId = this.$route.params.id;
    this.isPageReady = false;
    let groupBody = {
      idGroupList: [],
    };
    if (proceed) {
      try {
        await this.standService.removeOwner(owner.idStand, owner.idUser);
        this.toastService.success(
          this.translations.success.owner_removed_succesfully
        );
      } catch (error) {
        this.toastService.error(this.translations.errors.somethingWrong);
      }

      const user = await this.userService.read(owner.idUser);
      groupBody.idGroupList = user.groups
        .map((g) => g.id)
        .filter((g) => g != this.ownerGroup[0].id);

      await this.userService.updateGroup(owner.idUser, groupBody);

      this.currentOwners = await this.standService.owners(standId);
    }

    if (this.$refs.usersTable.tableOptions)
        await this.updateSsp(this.$refs.usersTable.currentFilters, this.$refs.usersTable.tableOptions);

    this.isPageReady = true;
  }

  async addOwner(item) {
    const standId = this.$route.params.id;
    const userId = item.id;
    const body = {
      idUser: item.id,
    };
    let groupBody = {
      idGroupList: [],
    };

    this.isPageReady = false;
    try {
      await this.standService.assignOwner(body, standId);
      this.toastService.success(this.translations.success.owner_assign);
    } catch (error) {
      this.toastService.error(this.translations.errors.somethingWrong);
    }
    const user = await this.userService.read(userId);

    groupBody.idGroupList = user.groups.map((g) => g.id);
    groupBody.idGroupList.push(this.ownerGroup[0].id);
    try {
      await this.userService.updateGroup(userId, groupBody);
    } catch (error) {
      this.toastService.error(this.translations.errors.user_already_owner);
    }

    this.currentOwners = await this.standService.owners(standId);

    if (this.$refs.usersTable.tableOptions)
        await this.updateSsp(this.$refs.usersTable.currentFilters, this.$refs.usersTable.tableOptions);

    this.isPageReady = true;


  }

  async updateSsp(filters, options) {
    const sspFilters = {
      ...filters,
      page: options.page,
      perPage: options.itemsPerPage,
      exclude: this.currentOwners.map(o => o.idUser)
    };

    if ((sspFilters?.orderBy ?? "").startsWith("enable")) {
      sspFilters.orderBy = sspFilters.orderBy.replace("enable", "isEnabled");
    }

    const res = await this.userService.paginatedList(sspFilters);
    this.model = res.result;
    this.sspLength = res.meta.totalItems;
  }

  async init() {
    const standId = this.$route.params.id;

    try {
      this.model = await this.userService.list();

      this.currentOwners = await this.standService.owners(standId);

      this.groups = await this.groupService.list();
      this.ownerGroup = this.groups.filter(
        (g) => g.name.toLowerCase() == "owner"
      );

      this.isPageReady = true;
    } catch (error) {
      this.toastService.error(this.translations.errors.somethingWrong);
    }
  }

  mounted() {
    this.init();
    this.$store.commit(
      "SET_SECTION_TITLE",
      this.translations.pageTitles.users_list
    );
  }
}
</script>

<style lang="scss">
.owner-list {
  &__actions-bar {
    float: right;

    & > button {
      margin-left: 15px;
    }
  }

  &__title-wrapper {
    padding: 35px 0 15px 0;
  }

  .btn-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 30px 0 30px;
    margin: 15px 30px 0 30px;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}
</style>
